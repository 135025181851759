.input-list-item {
  &__label {
    width: 150px;
    .input-group-text {
      font-size: 12px;
    }
  }

  &__unit {
    min-width: 80px;
    font-size: 14px !important;
  }
}
