.bg-tartan {
    background-color: hsl(2, 57%, 40%);
    background-image: repeating-linear-gradient(
            transparent,
            transparent 50px,
            rgba(0, 0, 0, 0.4) 50px,
            rgba(0, 0, 0, 0.4) 53px,
            transparent 53px,
            transparent 63px,
            rgba(0, 0, 0, 0.4) 63px,
            rgba(0, 0, 0, 0.4) 66px,
            transparent 66px,
            transparent 116px,
            rgba(0, 0, 0, 0.5) 116px,
            rgba(0, 0, 0, 0.5) 166px,
            rgba(255, 255, 255, 0.2) 166px,
            rgba(255, 255, 255, 0.2) 169px,
            rgba(0, 0, 0, 0.5) 169px,
            rgba(0, 0, 0, 0.5) 179px,
            rgba(255, 255, 255, 0.2) 179px,
            rgba(255, 255, 255, 0.2) 182px,
            rgba(0, 0, 0, 0.5) 182px,
            rgba(0, 0, 0, 0.5) 232px,
            transparent 232px
        ),
        repeating-linear-gradient(
            270deg,
            transparent,
            transparent 50px,
            rgba(0, 0, 0, 0.4) 50px,
            rgba(0, 0, 0, 0.4) 53px,
            transparent 53px,
            transparent 63px,
            rgba(0, 0, 0, 0.4) 63px,
            rgba(0, 0, 0, 0.4) 66px,
            transparent 66px,
            transparent 116px,
            rgba(0, 0, 0, 0.5) 116px,
            rgba(0, 0, 0, 0.5) 166px,
            rgba(255, 255, 255, 0.2) 166px,
            rgba(255, 255, 255, 0.2) 169px,
            rgba(0, 0, 0, 0.5) 169px,
            rgba(0, 0, 0, 0.5) 179px,
            rgba(255, 255, 255, 0.2) 179px,
            rgba(255, 255, 255, 0.2) 182px,
            rgba(0, 0, 0, 0.5) 182px,
            rgba(0, 0, 0, 0.5) 232px,
            transparent 232px
        ),
        repeating-linear-gradient(
            125deg,
            transparent,
            transparent 2px,
            rgba(0, 0, 0, 0.2) 2px,
            rgba(0, 0, 0, 0.2) 3px,
            transparent 3px,
            transparent 5px,
            rgba(0, 0, 0, 0.2) 5px
        );
}

.bg-japanese-cubes {
    background-color: #556;
    background-image: linear-gradient(30deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445),
        linear-gradient(150deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445),
        linear-gradient(30deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445),
        linear-gradient(150deg, #445 12%, transparent 12.5%, transparent 87%, #445 87.5%, #445),
        linear-gradient(60deg, #99a 25%, transparent 25.5%, transparent 75%, #99a 75%, #99a),
        linear-gradient(60deg, #99a 25%, transparent 25.5%, transparent 75%, #99a 75%, #99a);
    background-size: 80px 140px;
    background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
}

.bg-brady-bunch {
    background-image: radial-gradient(
            closest-side,
            transparent 0%,
            transparent 75%,
            #b6cc66 76%,
            #b6cc66 85%,
            #edffdb 86%,
            #edffdb 94%,
            #ffffff 95%,
            #ffffff 103%,
            #d9e6a7 104%,
            #d9e6a7 112%,
            #798b3c 113%,
            #798b3c 121%,
            #ffffff 122%,
            #ffffff 130%,
            #e0ead7 131%,
            #e0ead7 140%
        ),
        radial-gradient(
            closest-side,
            transparent 0%,
            transparent 75%,
            #b6cc66 76%,
            #b6cc66 85%,
            #edffdb 86%,
            #edffdb 94%,
            #ffffff 95%,
            #ffffff 103%,
            #d9e6a7 104%,
            #d9e6a7 112%,
            #798b3c 113%,
            #798b3c 121%,
            #ffffff 122%,
            #ffffff 130%,
            #e0ead7 131%,
            #e0ead7 140%
        );
    background-size: 110px 110px;
    background-color: #c8d3a7;
    background-position: 0 0, 55px 55px;
}
