@font-face {
    font-family: "Chalky";
    font-weight: normal;
    font-style: normal;
    src: url("./chalky/b86880e886b766ddd4156b5d208239c6.eot"); /* IE9*/
    src: url("./chalky/b86880e886b766ddd4156b5d208239c6.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("./chalky/b86880e886b766ddd4156b5d208239c6.woff2") format("woff2"),
        /* chrome、firefox */ url("./chalky/b86880e886b766ddd4156b5d208239c6.woff") format("woff"),
        /* chrome、firefox */ url("./chalky/b86880e886b766ddd4156b5d208239c6.ttf") format("truetype"),
        /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
            url("./chalky/b86880e886b766ddd4156b5d208239c6.svg#Chalky W00 Regular") format("svg"); /* iOS 4.1- */
}
@font-face {
    font-family: "Crayon";
    font-weight: normal;
    font-style: normal;
    src: url("./crayon-crumble/CrayonCrumble.ttf") format("truetype");
}
@font-face {
    font-family: "TallyMark";
    font-weight: normal;
    font-style: normal;
    src: url("./tally-mark/TallyMark.ttf") format("truetype");
}
