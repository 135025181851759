@import "src/components/coiffeur-slate/configuration";
.slate-team-block {
    height: $slate-header-height;
    border-radius: 0;
    &__member {
        &-name {
            height: 1em;
            overflow: hidden;
            text-overflow: clip !important;
            width: 50px;
        }
        &-image {
            border: 2px solid white;
        }
    }
}
