.marks-page {
    &__list-entry {
        font-size: 1.5em;
    }
    input[type="checkbox"] {
        width: 30px;
        height: 30px;
    }
    .text-highlight {
        font-style: normal;
        border-radius: 1em 0 1em 0;
        text-shadow: 1px 1px 1px #fff;
        background-image: linear-gradient(
            -100deg,
            rgba(255, 250, 150, 0.15),
            rgba(255, 250, 150, 0.8) 100%,
            rgba(255, 250, 150, 0.25)
        );
    }

    ul.list {
        font-size: 14px;
        list-style: none;
        position: relative;
        width: 350px;
        margin: 40px auto;
        border: 1px solid #f5f5f5;
        padding: 20px 0;
        background-color: #fbfbfb;
        box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.5), 0 4px 3px -2px rgba(0, 0, 0, 0.1),
            0 1px 1px 0 rgba(0, 0, 0, 0.2);
        text-shadow: 0 1px 0 white;
    }
    ul.list:before,
    ul.list:after {
        content: "";
        position: absolute;
        left: 2px;
        top: 3px;
        background-color: #f9f9f9;
        border: 1px solid #f5f5f5;
        width: 99%;
        height: 100%;
        z-index: -1;
        box-shadow: 0 7px 1px -3px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.2);
        transform: rotate(2.5deg);
    }
    ul.list:after {
        top: 4px;
        transform: rotate(-1deg);
    }
    .list li {
        border-bottom: 1px solid rgba(205, 220, 243, 1);
        padding: 8px 10px 2px 35px;
    }
    .list li:first-of-type:after {
        content: "";
        position: absolute;
        left: 25px;
        top: -1px;
        border-left: 1px solid rgba(240, 214, 213, 1);
        border-right: 1px solid rgba(240, 214, 213, 1);
        width: 1px;
        height: 101%;
    }
}
/**
 * Paper Stack
 */
