.discipline-image {
  &__preview {
    background-position: center;
    background-size: cover;
    &--sm {
      width: 90px;
      height: 90px;
      font-size: 90px;
    }
    &--xs {
      width: 45px;
      height: 45px;
      font-size: 45px;
    }
    &--md {
      width: 150px;
      height: 150px;
      font-size: 150px;
    }
    &--lg {
      width: 250px;
      height: 250px;
      font-size: 250px;
    }
    &--rounded {
      border-radius: 100%;
    }
    &--inverted {
      filter: invert(1);
    }
  }
}
