.costs-timeline-statistics {
    &-body {
        font-size: 0.65rem;
    }
    .custom-tooltip {
        font-size: 0.75rem;
        background: white;
        padding: 0.5rem;
        border-radius: 0.2rem;
        outline: 1px solid black;
        .date {
            font-weight: bold;
        }
        th {
            padding-top: 0.85rem;
        }
        td:first-child {
            padding-right: 2rem;
            font-weight: bold;
        }
        td:last-child {
            text-align: right;
        }
        tr {
            border-bottom: 1px solid #e0e0e0;
        }
    }
}
