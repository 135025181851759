.welcome-page {
    &__user-image {
        background: #efeff4;
        border: 2px solid #efeff4;
    }
}

.header-image {
    background-size: cover;
    filter: blur(3px);
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.background-image-header {
    position: relative;
    > img {
        z-index: -1;
        pointer-events: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        width: 100%;
        height: 100%;
        filter: blur(2px);
        object-fit: cover;
    }
}
