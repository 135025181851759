.mt--5 {
    margin-top: -50px;
}
.font-chalk {
    font-family: "Chalky" !important;
}
.font-crayon {
    font-family: "Crayon" !important;
}
.font-tally-mark {
    font-family: "TallyMark" !important;
}
.font-chalk-label {
    font-family: "Istok Web", sans-serif !important;
}
.bg-slate {
    background: url("../img/slate.png") repeat;
}
.bg-chalk {
    background: url("../img/whitenoise-50x50.png") repeat;
}
.bg-wood {
    background: url("../img/wood-pattern.jpg") repeat;
}
.border-2 {
    border-width: 2px !important;
}
.border-3 {
    border-width: 3px !important;
}
.border-4 {
    border-width: 4px !important;
}
.text-1-1 {
    font-size: 1.1em;
}
.text-1-2 {
    font-size: 1.2em;
}
.text-1-3 {
    font-size: 1.3em;
}
.text-1-4 {
    font-size: 1.4em;
}
