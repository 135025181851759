.user-image-setting-card {
  &__circular-progressbar {
    width: 100px;

    text {
      font-size: 12px !important;
    }
  }
}

.display-image-preview {
  background-position: center;
  background-size: cover;
  width: 90px;
  height: 90px;
}
