@import "../configuration";
.slate-result-block {
    height: $discipline-height;
    color: rgba(255, 255, 255, 0.9);
    &__numbers {
        font-size: 3em;
    }
    &__match-indicator {
        top: 0;
        right: 0;
        position: absolute;
    }
    &__strike {
        height: 4px;
        width: 60%;
        max-width: 100px;
        border-radius: 4px 3px 3px 4px;
        filter: opacity(0.9);
        transform: rotate(-25deg);
        &-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 50%;
            &--right {
                left: 50%;
            }
        }
    }
}
