.choose-player {
    &__tile {
        background: rgba(0, 0, 0, 0.8);
        border-radius: 20px;
        transition: all 0.1s ease-in-out;
        &--unselected {
            opacity: 0.3;
        }
        &--selected {
            transform: scale(1.1);
        }
    }
}
