.schieber-team {
    &__win-button {
        outline: 0 !important;
        border: none !important;
        background: rgba(170, 45, 45, 0.2) !important;
        &--won {
            background: #02b402 !important;
        }
    }
}
