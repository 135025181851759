@import "../configuration";
.slate-category-column {
    &__metadata-wrapper {
        max-width: $discipline-max-width;
        top: $slate-border-width;
        width: inherit;
        border-top-left-radius: 24px;
    }
    &__total-wrapper {
        max-width: $discipline-max-width;
        bottom: $slate-border-width;
        width: inherit;
        border-bottom-left-radius: 24px;
        height: $total-height;
        font-size: 1.3em;
    }
}
