.coiffeur-per-team-statistics {
    &-body {
        font-size: 0.65rem;
    }
    .bar-label {
        display: flex !important;
        flex-wrap: nowrap;
        font-size: 1em;
        fill: rgba(0, 0, 0, 0.9);
        width: 100px;
        &-white {
            fill: rgba(255, 255, 255, 1);
        }
        &-gray {
            fill: rgba(0, 0, 0, 0.3);
        }
        &-green {
            fill: #32cd32;
        }
        &-red {
            fill: #df1313;
        }
        &-bold {
            font-size: 1.125em;
            font-weight: bold;
        }
    }
}
