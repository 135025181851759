@import "configuration";
.coiffeur-slate {
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
    overflow: auto;
    border-radius: 27px;
    padding-top: $slate-header-height - $slate-border-width;
    padding-bottom: $total-height - $slate-border-width;
    position: relative;
    max-width: $slate-max-width;

    &-wrapper {
        overflow: hidden;
        padding: $slate-border-width;
    }
    &__column {
        &:first-child {
            width: $discipline-width;
            max-width: $discipline-max-width;
        }
        &:nth-child(2),
        &:nth-child(3) {
            width: $results-width;
            max-width: $results-max-width;
        }
    }
}
