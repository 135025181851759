@import "../configuration";

.slate-result-column {
    &__team-wrapper {
        max-width: $results-max-width;
        top: $slate-border-width;
        width: inherit;
        border-top-right-radius: 24px;
        border-bottom: 4px solid white;
    }
    &__total-wrapper {
        max-width: $results-max-width;
        bottom: $slate-border-width;
        width: inherit;
        height: $total-height;
        font-size: 2.5em;
        border-top: 4px solid white;
    }
    &:nth-child(2) {
        .slate-result-column {
            &__team-wrapper {
                border-right: 5px solid white;
            }
            &__total-wrapper {
                border-right: 5px solid white;
            }
        }
    }
    &:nth-child(3) {
        .slate-result-column {
            &__team-wrapper {
                border-top-right-radius: 24px;
            }
            &__total-wrapper {
                border-bottom-right-radius: 24px;
            }
        }
    }
}
