.total-costs-statistics {
    .bar-label {
        font-size: 1rem;
        font-weight: bold;
        &-white {
            font-size: 1.1rem;
            fill: white;
        }
    }
}
