@import "configuration";

.schieber-slate {
    &__divider-line {
        height: 3px;
        background: #aa2d2d;
        opacity: 0.2;
    }

    .slate {
        overflow: scroll;
        overflow-x: hidden;
        scrollbar-width: none;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none; /* IE 11 */
        scrollbar-color: transparent transparent;
        scrollbar-width: none;
        overflow: auto;
        border-radius: 27px;
        position: relative;
        max-width: $slate-max-width;

        &-wrapper {
            overflow: hidden;
            padding: $slate-border-width;
        }
    }
}
