.choose-points {
    &__digit {
        font-size: 7em;
    }
    &__checkbox--inactive {
        transform: scale(0.8);
        opacity: 0.5;
    }
    input[type="checkbox"] {
        display: none;
    }
    input[type="checkbox"] + label {
        width: 63px;
        height: 35px;
        border-radius: 30px;
        border: 2px solid #ddd;
        background-color: #eee;
        display: inline-block;
        content: "";
        float: left;
        margin-right: 5px;
        transition: background-color 0.5s linear;
    }
    input[type="checkbox"] + label:hover {
        cursor: pointer;
    }
    input[type="checkbox"] + label::before {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        background-color: #fff;
        display: block;
        content: "";
        float: left;
        margin-right: 5px;
        transition: margin 0.1s linear;
        box-shadow: 0px 0px 5px #aaa;
    }
    input[type="checkbox"]:checked + label {
        background-color: rgba(0, 255, 0, 0.91);
    }
    input[type="checkbox"]:checked + label::before {
        margin: 0 0 0 30px;
    }
}
