.players-and-teams-single-discipline-chart {
    .bar-label {
        display: flex !important;
        flex-wrap: nowrap;
        font-size: 1em;
        fill: rgba(0, 0, 0, 0.9);
        width: 100px;
        &-white {
            fill: rgba(255, 255, 255, 1);
        }
        &-gray {
            fill: rgba(0, 0, 0, 0.3);
        }
        &-green {
            fill: rgb(55, 142, 0);
        }
        &-bold {
            font-size: 1.125em;
            font-weight: bold;
        }
    }
}
