$slate-header-height: 80px;
$discipline-height: 60px;
$total-height: 50px;
$slate-border-width: 3px;
$slate-max-width: 700px;

$discipline-width: 26%;
$discipline-max-width: $slate-max-width * 0.26;
$results-width: 37%;
$results-max-width: $slate-max-width * 0.37;
