.matches-per-team-statistics {
    .bar-label {
        font-size: 0.75rem;
        font-weight: bold;
        &-white {
            font-size: 1.1rem;
            fill: white;
        }
    }
}
